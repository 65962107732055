import React from "react";
import { Box, Typography } from "@mui/material";

const CustomBarChart = ({ data }) => {
  const maxValue = 100; // Valor máximo para la escala

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflowX: "auto", // Permitir desplazamiento horizontal si las barras no caben
        padding: "10px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          gap: "10px",
          minWidth: "100%", // Asegurar que ocupe todo el ancho
        }}
      >
        {/* Renderizar barras dinámicamente */}
        {data.map((item, index) => {
          const barHeight = Math.max((item.total / maxValue) * 100, 1); // Altura proporcional (mínimo 1%)
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "50px", // Ancho de cada barra
              }}
            >
              {/* Etiqueta encima de la barra */}
              <Typography
                variant="caption"
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  mb: 1,
                }}
              >
                {item.total}
              </Typography>

              {/* Barra */}
              <Box
                sx={{
                  width: "100%",
                  height: `${barHeight}%`,
                  backgroundColor: item.total > 50 ? "#82ca9d" : "#8884d8",
                  borderRadius: "4px 4px 0 0",
                  transition: "height 0.3s ease-in-out",
                }}
              ></Box>

              {/* Etiqueta del eje X */}
              <Box
                sx={{
                  marginTop: "5px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column", // Número arriba, día abajo
                  alignItems: "center", // Centrado horizontal
                  lineHeight: "1.2",
                }}
              >
                <Typography variant="caption" sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  {item.day.split(" ")[0]} {/* Número del día */}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: "10px", color: "#666" }}>
                  {item.day.split(" ")[1]} {/* Día de la semana */}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomBarChart;
