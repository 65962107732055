import React from 'react'
import { useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {db} from '../../firebase';
import { Routes, 
          Route,
          NavLink,
          Navigate,
        } from "react-router-dom";
import DevelopComponent from '../../components/DevelopComponent';
import Managementriders from './Managementriders/Managementriders';
import Metricsriders from './Metricsriders/Metricsriders';
import DetalleMetricsriders from './Metricsriders/DetalleMetricsriders';
import {auth} from '../../firebase'
import Page404 from '../Page404';
//import './admin.js'

    
const Adminriders = (props, ...rest) => {
//console.log(props)
const dispatch = useDispatch()

const [togle, setTogle]= React.useState(false)

const [firebaseUser, setFirebaseUser] = React.useState(false)

React.useEffect(() => {
  const fetchUser = () => {
    auth.onAuthStateChanged(user => {
        //console.log(user)
        if(user){
            setFirebaseUser(user)
        }else{
            setFirebaseUser('invitado')        
        }
    })
  }
  fetchUser()
}, [firebaseUser])


React.useEffect(() => {
      }, [togle])




const handleClick = (e) =>{ 
    var current = e.target
    var id = current.id
    var name = current.name
    var value
    if(id){value = id}else{value = name}
    //console.log(value)
    setTogle(false)
    if(value==="gestionperformance"){
 
    }
}

const handlemenu = () => {
    setTogle(!togle)
}

const RutaProtegida = ({component, path, ...rest}) => {
  if(localStorage.getItem('userRider')){
    const usuarioStorage = JSON.parse(localStorage.getItem('userRider'))
  // console.log('Ruta Protegida')
  // console.log(usuarioStorage.uid)
  // console.log(usuarioStorage.roll)
  // console.log(firebaseUser.uid)
  if(usuarioStorage.uid === firebaseUser.uid){
        if((process.env.REACT_APP_RIDERS_ADMIN1===usuarioStorage.roll)||(process.env.REACT_APP_RIDERS_ADMIN2===usuarioStorage.roll)){ //.indexOf(usuarioStorage.roll)){
         // console.log(usuarioStorage.roll)
          return <Route component={component} path={path} {...rest} />
        }else{ 
        return <Navigate to="/404" component={Page404}  />
      }
    }else{
      //console.log('redirecciona a login')
      return <Navigate to="/" {...rest} />
    }
  //}
  }else{
  //console.log('redirecciona a login')
    return <Navigate to="/" {...rest} />
  }      
}
return (
  <div>

  {
        firebaseUser === false ?  (<div>Cargando...</div>):(<>     
    <div className='bodyAdmin'>
    <div className="simple-admin">
      <div data-component="sidebar">
        <div className="sidebar" onClick={handlemenu}>
          <ul className={togle===true?("list-group flex-column d-inline-block first-menu mostrar" ):("list-group flex-column d-inline-block first-menu ocultar")}>
            <li className="list-group-item py-2">
            <button className="btn-sidebar"onClick={handlemenu}>{togle===true?(<MenuOpenIcon/>):(<MenuIcon/>)}</button>
            </li>
            <li className="list-group-item py-2">
              <>
                <img src="/assets/593.S2-Users.png" height="40" className="mr-4" alt = ""  ></img><span>Usuarios drivers</span>
              </>
              <ul className="list-group flex-column d-inline-block submenu svg-submenu">
                <li className="list-group-item" >

                  <NavLink to="/admin/Metricas-riders"   name="gestionperformance"    >
                    <img  onClick={handleClick}  name="gestionperformance" src="/assets/617.S2-ManageData.png" height="40" width="40" className="ml-2 mr-4 d-inline-block"alt = "" ></img>
                    <div onClick={handleClick} id="gestionperformance" className="text d-inline-block align-middle">
                      Data desempeño 
                    </div>
                  </NavLink>

                </li>
                <li className="list-group-item">
                  <NavLink to="/admin/Gestion-riders" name="filesrider"  >
                    <img onClick={handleClick}  name="filesrider" src="/assets/629.S2-FileManager.png" height="40" width="40" className="ml-2 mr-4 d-inline-block"alt = "" ></img>
                    <div onClick={handleClick}  id="filesrider" className="text d-inline-block align-middle">
                      Gestion de documentos
                    </div>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div> 
      </div>
    </div> 
    <div className='dashcontenido'> 
    <Routes>
                <RutaProtegida  component={Managementriders} path="/admin"  {...rest} exact/>
                <RutaProtegida component={Managementriders} path="/admin/Gestion-riders"  exact/>
                <RutaProtegida component={Metricsriders} path="/admin/Metricas-riders"  exact/>
                <RutaProtegida component={DetalleMetricsriders} path="/admin/Metricas-riders/:id" exact/>
                <RutaProtegida component={Managementriders} path="/admin/finanzas-riders"  exact/>
             
    </Routes>
    </div>
</div>
</>)
}
</div>
  )
}

export default Adminriders