import React, { useEffect, useState } from "react";
import {
  Grid,
  Avatar,
  Box,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Button,
  Container,
} from "@mui/material";
import { db } from "../../../firebase";
import ActualizarDriverPerformance from "./ActualizarDriverPerformance";
import ActualizarScheduleTimes from "./ActualizarScheduleTimes";
import axios from "axios";

const DetalleMetricsriders = (props) => {
  const userUid = props.match.params.id;

  const [data, setData] = useState({});
  const [dataDriverPerformance, setDataDriverPerformance] = useState([]);
  const [dataScheduleTimes, setDataScheduleTimes] = useState([]);
  const [lastDataDriverPerformance, setLastDataDriverPerformance] = useState({});
  const [lastDataScheduleTimes, setLastDataScheduleTimes] = useState({});
  const [rankingPosition, setRankingPosition] = useState("No definido...");
  const [togle, setTogle] = useState(true);

  const handleSetTogle = () => setTogle(!togle);

  useEffect(() => {
    obtenerDatosdeRider(userUid);
    obtenerDriverPerformance(userUid);
    obtenerDriverScheduleTimes(userUid);
    getRidresRanking(userUid);
  }, [togle]);

  const obtenerDatosdeRider = async (userUid) => {
    try {
      const accountants = await db.collection("drivers").doc(userUid).get();
      setData(accountants.data());
    } catch (error) {
      console.error("Error al obtener datos del rider:", error);
    }
  };

  const obtenerDriverPerformance = async (userUid) => {
    try {
      const accountantsPerformance = await db
        .collection("drivers")
        .doc(userUid)
        .collection("driverPerformance")
        .get();

      const metricsRider = accountantsPerformance.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      metricsRider.sort((a, b) =>
        a.LastPediodoAdd < b.LastPediodoAdd ? 1 : -1
      );
      setDataDriverPerformance(metricsRider);
      setLastDataDriverPerformance(metricsRider[0]);
    } catch (error) {
      console.error("Error al obtener desempeño del rider:", error);
    }
  };

  const obtenerDriverScheduleTimes = async (userUid) => {
    try {
      const accountantsPerformance = await db
        .collection("drivers")
        .doc(userUid)
        .collection("scheduleTimes")
        .get();

      const metricsRiderTimes = accountantsPerformance.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      metricsRiderTimes.sort((a, b) =>
        a.LastPediodoAdd < b.LastPediodoAdd ? 1 : -1
      );
      setDataScheduleTimes(metricsRiderTimes);
      setLastDataScheduleTimes(metricsRiderTimes[0]);
    } catch (error) {
      console.error("Error al obtener tiempos del rider:", error);
    }
  };

  const getRidresRanking = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BICCI_API_DEV}driverRanking/${id}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_EBIEX_CLOUD_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === "404") {
        setRankingPosition("No definido...");
      } else {
        setRankingPosition(response.data.position || "No definido...");
      }
    } catch (error) {
      console.error("Error al obtener ranking:", error);
      setRankingPosition("No definido...");
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Detalles del Rider
      </Typography>
      <Grid container spacing={3}>
        {/* Rider Details */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ textAlign: "center" }}>
                <Avatar
                  src={data.avatar}
                  sx={{ width: 100, height: 100, margin: "auto" }}
                />
                <Typography variant="h6" gutterBottom>
                  {data.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {data.email}
                </Typography>
                <Typography
                  variant="body2"
                  color={data.active ? "success.main" : "error.main"}
                >
                  {data.active ? "Activo" : "Inactivo"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Ranking */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader title="Ranking" />
            <CardContent>
              <Typography variant="h5" align="center">
                {rankingPosition}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Desempeño */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader title="Desempeño" />
            <CardContent>
              <Typography variant="body2" align="center">
                Último periodo: {lastDataDriverPerformance?.LastPediodoAdd || "N/A"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Servicio */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader title="Servicio" />
            <CardContent>
              <Typography variant="body2" align="center">
                Último periodo: {lastDataScheduleTimes?.LastPediodoAdd || "N/A"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Actions */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <ActualizarDriverPerformance
                id={userUid}
                handleSetTogle={handleSetTogle}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <ActualizarScheduleTimes
                id={userUid}
                handleSetTogle={handleSetTogle}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DetalleMetricsriders;
