import React, { useState } from "react";
import { Dialog, Box, Typography, Select, MenuItem, Button } from "@mui/material";

const RejectionDialog = ({ open, onClose, orderId, handleRechazar }) => {
  // Estado para el motivo seleccionado
  const [selectedReason, setSelectedReason] = useState("");

  console.log(orderId)
  // Lista de motivos de rechazo
  const rejectionReasons = [
    "Dirección incorrecta",
    "Cliente no disponible",
    "Producto dañado",
    "Problemas de pago",
    "Falta de stock",
  ];

  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleReject = () => {
    if (!selectedReason) {
      alert("Por favor selecciona un motivo de rechazo.");
      return;
    }
    handleRechazar(orderId, selectedReason); // Llama a la función con el motivo seleccionado
    onClose(); // Cierra el diálogo
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={2}>
        <Typography variant="h6">¿Motivo de rechazo de esta orden?</Typography>
        <Select
          fullWidth
          value={selectedReason}
          onChange={handleReasonChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Selecciona un motivo
          </MenuItem>
          {rejectionReasons.map((reason, index) => (
            <MenuItem key={index} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        <Box marginTop={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="error"
            onClick={handleReject}
            disabled={!selectedReason} // Deshabilita si no hay motivo seleccionado
          >
            Rechazar
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RejectionDialog;
