import React, {useState} from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";
import Carouselhomeauto from "../../../components/Carouselhome/Carouselhomeauto";

import imgh001 from "./h001.png";
import imgh002 from "./h002.png";
import imgh003 from "./h003.png";
import imgh004 from "./h004.png";

const Bicciriders = () => {

  const FlipCardContainer = styled(Box)(({ theme }) => ({
    perspective: "1000px",
    width: "100%",
    height: "300px",
    position: "relative",
  }));
  
  const CardInner = styled(Box)(({ flipped }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    transformStyle: "preserve-3d",
    transform: flipped ? "rotateY(180deg)" : "none",
    transition: "transform 0.6s",
  }));
  
  const CardFace = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: theme.shape.borderRadius,
  }));
  
  const FrontFace = styled(CardFace)(() => ({
    zIndex: 2,
  }));
  
  const BackFace = styled(CardFace)(() => ({
    transform: "rotateY(180deg)",
    background: "#f5f5f5",
  }));
  
  const images = [imgh001, imgh002, imgh003, imgh004];
    const [flippedCards, setFlippedCards] = useState([false, false, false, false]);
  
    const handleFlip = (index) => {
      const updatedFlips = [...flippedCards];
      updatedFlips[index] = !updatedFlips[index];
      setFlippedCards(updatedFlips);
    };
  
    
  return (
    <Container className="bodyriderhome">
      {/* Carousel */}
      <Carouselhomeauto />
      <Box>
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        ¿Qué te ofrecemos?
      </Typography>

      {/* Flip Cards */}
    
      <Grid container spacing={3}>
      {["INGRESOS", "FUNDACIÓN", "BENEFICIOS", "MOVILIDAD"].map((title, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <FlipCardContainer>
            <CardInner flipped={flippedCards[index]} onClick={() => handleFlip(index)}>
              <FrontFace>
                <Card>
                  <CardMedia
                    component="img"
                    image={images[index]}
                    alt={`Feature ${index + 1}`}
                    sx={{ height: "200px" }}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {title}
                    </Typography>
                  </CardContent>
                </Card>
              </FrontFace>
              <BackFace>
                <Card sx={{ height: "100%" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Más información
                    </Typography>
                    <Typography variant="body2">
                      {
                        [
                          "Todos los ingresos son transparentes. Tanto el despacho como la propina son 100% para ti.",
                          "Por medio de la fundación Bicci podrás optar a beneficios sociales para ti y tu familia.",
                          "Tenemos beneficios especialmente pensados para ti.",
                          "Queremos fomentar la electromovilidad. Si no tienes vehículo, te damos facilidades para obtener tu propia eco-bicci.",
                        ][index]
                      }
                    </Typography>
                  </CardContent>
                </Card>
              </BackFace>
            </CardInner>
          </FlipCardContainer>
        </Grid>
      ))}
    </Grid>
      </Box>
      <Box>
      <Typography variant="h4" align="center" color="primary" sx={{ my: 4 }}>
        ¡Por un mejor futuro!
      </Typography>
      </Box>
      {/* Video Section */}
      <Box className="videocontainer" sx={{ textAlign: "center", my: 4 }}>
        <ReactPlayer
          url="https://www.youtube-nocookie.com/embed/0QYT6bIQqgw"
          controls
          width="100%"
          height="450px"
          config={{
            youtube: {
              playerVars: { origin: "http://localhost:8100" },
            },
          }}
        />
      </Box>

      {/* Info Cards */}
      <Grid  spacing={3} className="justifycontent">
        <Grid item xs={12} sm={12}>
          <Card className="card-type">
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Fundación Bicci
              </Typography>
              <Typography variant="body2">
                Te invitamos a ser parte del programa de ayuda social dirigido a
                ti y tu familia.
              </Typography>
              <NavLink to="#">Infórmate aquí</NavLink>
              <CardMedia
                component="img"
                image="https://bicci.cl/wp-content/uploads/2020/08/Banner-768-x-511.jpg"
                alt="Fundación Bicci"
                sx={{ mt: 2, borderRadius: 2 }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card className="card-type">
            <CardContent>
              <CardMedia
                component="img"
                image="https://bicci.cl/wp-content/uploads/2020/08/Bicicleta-01-01-copia-copia-768x593.jpg"
                alt="Eco Bicci"
                sx={{ mb: 2, borderRadius: 2 }}
              />
              <Typography variant="h5" gutterBottom>
                ¡Súbete a tu propia Eco Bicci!
              </Typography>
              <Typography variant="body2">
                Reduce un 50% el tiempo de desplazamiento en comparación con una
                bicicleta normal. Te damos facilidades para obtener tu propia{" "}
                <strong>Bicci eléctrica</strong>.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Buttons Section */}
      <Box sx={{ textAlign: "center", my: 4 }}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to="#"
          sx={{ m: 1 }}
        >
          Registra pago
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to="/login"
          sx={{ m: 1 }}
        >
          Acceso Bicci Riders
        </Button>
      </Box>
    </Container>
  );
};

export default Bicciriders;
