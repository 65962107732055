import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button, Grid, Card, CardContent } from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import { translateStatesBicciFilter, translateStatesfalabbela } from "../../Utils/translateStates";
import moment from "moment";
import CustomBarChart from "../CustomBarChart/CustomBarChart";

const WeeklyOrders = ({ orders }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [weeks, setWeeks] = useState([]); 
   const [totalDeliveries, setTotalDeliveries] = useState(0);

useEffect(() => {
  // Generar semanas basadas en el calendario
  const generatedWeeks = generateWeeks();
//  console.log("Semanas generadas:", generatedWeeks); // Verificar semanas generadas
  setWeeks(generatedWeeks);

  if (generatedWeeks.length > 0) {
    const defaultWeek = generatedWeeks[0].start.toISOString().split("T")[0];
  //  console.log("Semana seleccionada por defecto:", defaultWeek); // Verificar selección por defecto
    setSelectedWeek(defaultWeek); // Seleccionar la primera semana por defecto
  }
}, []);

  useEffect(() => {
    if (selectedWeek) {
      const groupedByWeek = groupOrdersByWeek(orders);
      updateChartData(selectedWeek, groupedByWeek);
    }
  }, [orders, selectedWeek]);

  const generateWeeks = () => {
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    const weeks = [];
    let current = getWeekStartDate(today);

    if (current > today) {
      current.setDate(current.getDate() - 7);
    }

    while (current >= sixMonthsAgo) {
      const weekEnd = new Date(current);
      weekEnd.setDate(current.getDate() + 6);

      weeks.push({
        start: new Date(current),
        end: weekEnd,
      });

      current.setDate(current.getDate() - 7);
    }

    return weeks;
  };

 const getWeekStartDate = (date) => {
    const dayOfWeek = date.getDay();
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const weekStart = new Date(date);
    weekStart.setDate(date.getDate() + diff);
    weekStart.setHours(0, 0, 0, 0);
    return weekStart;
  };


  const groupOrdersByWeek = (orders) => {
    const grouped = {};

    orders.forEach((order) => {
      const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
      if (!date) return;

      const weekStart = getWeekStartDate(date);
      const weekKey = weekStart.toISOString().split("T")[0];

      if (!grouped[weekKey]) {
        grouped[weekKey] = [];
      }
      grouped[weekKey].push({ ...order, date });
    });

    return grouped;
  };

  const convertFirestoreTimestampToDate = (timestamp) => {
    if (!timestamp) return null;
    if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
      return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
    }
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  };

  const updateChartData = (weekKey, groupedByWeek) => {
    const weekStart = new Date(weekKey);
    const weekOrders = groupedByWeek[weekKey] || [];

    // Generar datos para los 7 días de la semana (lunes a domingo)
    const dailyData = Array.from({ length: 7 }, (_, i) => {
      const dayDate = new Date(weekStart);
      dayDate.setDate(weekStart.getDate() + i); // Avanzar de lunes a domingo
      const dayKey = dayDate.toISOString().split("T")[0];

      // Buscar cuántas órdenes corresponden a este día
      const totalOrders = weekOrders.filter(
        (order) => order.date.toISOString().split("T")[0] === dayKey
      ).length;

      return {
        // Formato: "24 Lun."
        day: `${dayDate.getDate()} ${dayDate.toLocaleDateString("es-ES", {
          weekday: "short",
        })}`,
        total: totalOrders, // Si no hay órdenes, será 0
      };
    });

    const totalDeliveries = dailyData.reduce((sum, item) => sum + item.total, 0);
    setTotalDeliveries(totalDeliveries);

    setChartData(dailyData);
  };

  const handleWeekChange = (week) => {
    setSelectedWeek(week);
  };


    const OrdersList = ({ orders }) => {
    return (
      <Grid container spacing={2}>
        {orders.map((order, index) => {
          // Intentamos formatear la fecha
          const dateToShow = order.updatedAt ?? order.createdAt;
          const dateFormatted = moment(
            dateToShow?.seconds ? dateToShow.seconds * 1000 : dateToShow
          ).format("DD/MM/YYYY HH:mm");

          return (
            <Grid item xs={12} key={order.day}>
              <Card>
                <CardContent>

                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Fecha: {order.day}
                  </Typography>
                  <Typography variant="body2">
                    Total: {order.total}
                  </Typography>
                  </Box>
                  </Box>
                 
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

return (
  <Box>
    {chartData.length === 0 ? (
      <Typography>No hay órdenes semanales completadas.</Typography>
    ) : (
      <>
          <Box sx={{ padding: "0px", backgroundColor: "#fff", borderRadius: "8px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  Entregas: {totalDeliveries} {/* Mostrar total de entregas */}
            </Typography>
            <CustomBarChart data={chartData} />
          </Box>

        {/* Botones para selección de semana */}
        <Box
          display="flex"
          justifyContent="flex-start"
          my={2}
          sx={{
            overflowX: "auto", // Activar desplazamiento horizontal
            whiteSpace: "nowrap", // Evitar que los botones se envuelvan
            width: "100%", // Asegurar que el contenedor ocupe todo el ancho
          }}
        >
          {weeks.map((week, index) => {
            const weekKey = week.start.toISOString().split("T")[0];
            return (
              <Button
                key={weekKey}
                variant={selectedWeek === weekKey ? "contained" : "outlined"}
                onClick={() => handleWeekChange(weekKey)}
                sx={{ mx: 1, fontSize: 10, minWidth: "200px", m: 1 }}
              >
                Semana {week.start.toLocaleDateString("es-ES")} - {week.end.toLocaleDateString("es-ES")}
              </Button>
            );
          })}
        </Box>

        {/* Órdenes de la semana seleccionada */}
        {selectedWeek && (
          <Box mt={2}>
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
              Semana {selectedWeek}
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <OrdersList orders={chartData} />
          </Box>
        )}
      </>
    )}
  </Box>
);

};

export default WeeklyOrders;
