import React, { useState } from "react";
import { AppBar, Box, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesionAccion } from "../../../redux/usuarioDucks";

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const activo = useSelector((store) => store.usuario.activo);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const cerrarSesion = () => {
    dispatch(cerrarSesionAccion());
  };

  const menuItems = [
    { text: "Home", link: "https://bicci.cl/" },
    { text: "Bicci-Riders", link: "/bicci-Riders" },
    { text: "Mi cuenta", link: "/mi-cuenta" },
    { text: "Equipamiento", link: "/equipamiento" },
    { text: "Contacto", link: "/contacto" },
  ];

  const drawer = (
    <Box sx={{ width: 250 }}>
      <IconButton onClick={handleDrawerToggle} sx={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} component={Link} to={item.link} onClick={handleDrawerToggle}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        {activo ? (
          <ListItem button component={Link} to="/login" onClick={cerrarSesion}>
            <ListItemText primary="Salir" />
          </ListItem>
        ) : (
          <ListItem button component={Link} to="/login">
            <ListItemText primary="Login" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{
      color: 'purple',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: '#f0f0f0',
      },
    }}>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          component={Link}
          to="/"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
          {menuItems.map((item, index) => (
            <Button key={index} component={Link} to={item.link} sx={{ color: "inherit" }}>
              {item.text}
            </Button>
          ))}
          {activo ? (
            <Button component={Link} to="/login" onClick={cerrarSesion} sx={{ color: "inherit" }}>
              Salir
            </Button>
          ) : (
            <Button component={Link} to="/login" sx={{ color: "inherit" }}>
              Login
            </Button>
          )}
        </Box>
        <IconButton
          color="inherit"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default Navbar;
