import React, { use, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

import DownloadIcon from "@mui/icons-material/Download";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CardHeader,
  Tab,
  Tabs,
    MenuItem, Select, Button, 
    Dialog,
    LinearProgress} from "@mui/material";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import QRCode from "react-qr-code";
import moment from "moment";

// ---- Recharts:
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import BuscarRutas from "../../../components/BuscarRutas/BuscarRutas";
import { translateStatesBicciFilter, translateStatesfalabbela } from "../../../Utils/translateStates";
import { exporExcelFileCircuit } from "../../../Utils/exporExcelFileCircuit";
import MapaOrdersPendientes from "../../../components/MapaOrdersPendientes/MapaOrdersPendientes";
import WeeklyOrders from "../../../components/WeeklyOrders/WeeklyOrders";
import MonthlyOrders from "../../../components/MonthlyOrders/MonthlyOrders";
import OrdersDailyView from "../../../components/OrdersDailyView/OrdersDailyView";

const Micuenta = () => {
  const userUid = useSelector((store) => store.usuario?.user?.uid);
  const navigate = useNavigate();


  // Control de pestañas y secciones
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedSection, setSelectedSection] = useState(0);
  const [sortOrder, setSortOrder] = React.useState("")

 

  // Datos de usuario
  const [userData, setUserData] = useState({});

  // Órdenes
  const [ordersRider, setOrdersRiders] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState({});

  // Agrupaciones de órdenes
  const [groupsedByWeek, setGroupsedByWeek] = useState({});
  const [groupsedByMonth, setGroupsedByMonth] = useState({});
  const [groupsedByDay, setGroupsedByDay] = useState({});

  // Estados adicionales
  const [viewMaps, setViewMaps] = useState(false);  // para controlar vista de mapa si se desea
  const [anchorEl, setAnchorEl] = useState(null);   // para el menú de tres puntos (opciones)
  
  // Campos búsqueda de rutas
  const [searchRoute, setSearchRoute] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([])

  const [openScanner, setOpenScanner] = useState(false);

  const [ selectionOrderData, setOrderData]  = useState(false);
  const [ openAccions , setOpenAccions] = useState(false);  

const handleOpenScanner = () => {
  setOpenScanner(true);
};

const handleCloseScanner = () => {
  setOpenScanner(false);
};

 const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };



  useEffect(() => {
    if(selectionOrderData){
    setOpenAccions(true)
    }
    
  }, [selectionOrderData]);
  // -----------------------------------------------------------------------------------
  // 1. Cargar datos iniciales del usuario y sus órdenes
  // -----------------------------------------------------------------------------------
  useEffect(() => {
    if (userUid) {
      fetchUserData(userUid);
      getOrders(userUid);
    }
  }, [userUid]);

 
  // -----------------------------------------------------------------------------------
  // 2. Cada vez que se obtienen las órdenes, se calculan los estados y agrupaciones
  // -----------------------------------------------------------------------------------
  useEffect(() => {
    if (ordersRider.length === 0) return;

    // Filtrar órdenes según su estado (ajusta la lógica de acuerdo a tu negocio)
    const pendientes = ordersRider.filter(order => order.status > 2 && order.status < 8);
    const canceladas = ordersRider.filter(order => order.status < 0);
    const creadas = ordersRider.filter(order => order.status === 0);
    const confirmadas = ordersRider.filter(order => order.status === 1);
    const completadas = ordersRider.filter(order => order.status === 8);
    const reclamos = ordersRider.filter(order => order.reclaind === true);

    // Guardar histórial (opcional, para otras visualizaciones)
    const historialeestados = {
      pendientes,
      canceladas,
      completadas,
      creadas,
      confirmadas,
      reclamos
    };

    setOrdersHistory(historialeestados);

    // Calcular agrupaciones
    calculateCoutes(completadas);
  }, [ordersRider]);

   const dowLoadCircuit = () => {
    console.log(ordersHistory.pendientes)
    exporExcelFileCircuit(ordersHistory.pendientes);
  }

  // -----------------------------------------------------------------------------------
  // 3. Función principal para crear agrupaciones (diaria, semanal, mensual)
  // -----------------------------------------------------------------------------------
  const calculateCoutes = async (completadas) => {
    // A) Conversión de Timestamps (si vienen de Firestore)
    function convertFirestoreTimestampToDate(timestamp) {
      if (!timestamp) return null;
      if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
      }
      const date = new Date(timestamp);
      return isNaN(date.getTime()) ? null : date;
    }

    // B) Agrupar por día
    function groupByDay(orders) {
      return orders.reduce((groups, order) => {
        const dateObj = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
        if (!dateObj) {
          console.warn("Invalid date for order:", order);
          return groups;
        }
        const dayKey = moment(dateObj).format("YYYY-MM-DD");
        if (!groups[dayKey]) {
          groups[dayKey] = [];
        }
        groups[dayKey].push(order);
        return groups;
      }, {});
    }

    // C) Agrupar por semana
    function getWeekOfYear(date) {
      if (!(date instanceof Date) || isNaN(date.getTime())) return null;
      const start = new Date(date.getFullYear(), 0, 1);
      const diff = date - start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
      return Math.floor(diff / (7 * 24 * 60 * 60 * 1000)) + 1;
    }

    function groupByWeek(orders) {
      return orders.reduce((groups, order) => {
        const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
        if (!date) {
          console.warn('Invalid date for order:', order);
          return groups;
        }
        const week = getWeekOfYear(date);
        if (!week) {
          console.warn('Could not determine the week for order:', order);
          return groups;
        }
        if (!groups[week]) {
          groups[week] = [];
        }
        groups[week].push(order);
        return groups;
      }, {});
    }

    // D) Agrupar por mes
    function groupByMonth(orders) {
      console.log(orders.length)
      return orders.reduce((groups, order) => {
        const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
        if (!date) {
          console.warn('Invalid date for order:', order);
          return groups;
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const key = `${year}-${month}`;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(order);
        return groups;
      }, {});
    }

    // E) Llamamos a las agrupaciones
    const dayGrouped = groupByDay(completadas);
    const weekGrouped = groupByWeek(completadas);
    const monthGrouped = groupByMonth(completadas);

    setGroupsedByDay(dayGrouped);
    setGroupsedByWeek(weekGrouped);
    setGroupsedByMonth(monthGrouped);
  };

  // -----------------------------------------------------------------------------------
  // 4. Obtener datos del usuario
  // -----------------------------------------------------------------------------------
  const fetchUserData = async (uid) => {
    try {
      const userCollectionRef = collection(db, "drivers");
      const userDocRef = doc(userCollectionRef, uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const data = userSnapshot.data();
        setUserData({
          ...data,
          // Ejemplo: formatear la fecha de creación
          createdAt: data?.createdAt?.seconds
            ? moment.unix(data.createdAt.seconds).format("DD/MM/YYYY")
            : "No especificado"
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // -----------------------------------------------------------------------------------
  // 5. Obtener las órdenes del rider
  // -----------------------------------------------------------------------------------
  const getOrders = async (uid) => {
    try {
      const collRef = collection(db, "orders");
      const q = query(collRef, where("driverID", "==", uid));
      const requestUser = await getDocs(q);
      const requestItems = requestUser.docs.map((doc) => ({
        id: doc.id, // ID del documento en Firestore
        ...doc.data()
      }));
      setOrdersRiders(requestItems);
    } catch (e) {
      console.error("Error al recibir documentos de cliente ", e);
    }
  };


  
  // -----------------------------------------------------------------------------------
  // 6. Vistas (contenido) para cada pestaña (diaria, semanal, mensual)
  // -----------------------------------------------------------------------------------
  // A) Componente auxiliar para listar órdenes en Cards
  const OrdersList = ({ orders }) => {
    return (
      <Grid container spacing={2}>
        {orders.map((order) => {
          // Intentamos formatear la fecha
          const dateToShow = order.updatedAt ?? order.createdAt;
          const dateFormatted = moment(
            dateToShow?.seconds ? dateToShow.seconds * 1000 : dateToShow
          ).format("DD/MM/YYYY HH:mm");

          return (
            <Grid item xs={12} key={order.id}>
              <Card>
                <CardContent>

                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ID: {order.id}
                  </Typography>
                  <Typography variant="body2">
                    Fecha: {dateFormatted}
                  </Typography>
                  <Typography variant="body2">
                    Destino: {order.toAddress ?? "No especificado"}
                  </Typography>
                  <Typography variant="body2">
                    Estado: { translateStatesBicciFilter[order.status] }
                  </Typography>
                  </Box>
                  <Box>
                  <Typography variant="body2">
                   {translateStatesfalabbela[order.intState]}
                  </Typography>
                    <Typography variant="body2">
                    Observación: {order.observationAdmin}
                  </Typography>
                  </Box>
                  </Box>
                 
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // B) Vista de Órdenes Diarias
  const callOrdersDailyView = () => {
    const dayKeys = Object.keys(groupsedByDay).sort();
    return groupsedByDay?(
      <OrdersDailyView groupsedByDay={groupsedByDay} ></OrdersDailyView>
    ):<LinearProgress></LinearProgress>;
  };

  // C) Vista de Órdenes Semanales + Gráfica
  const OrdersWeeklyView = () => {
    const weekKeys = Object.keys(groupsedByWeek).sort((a, b) => +a - +b);
    const chartData = weekKeys.map((wk) => ({
      week: `Sem ${wk}`,
      total: groupsedByWeek[wk].length
    }));

    return (
      <Box>
        {weekKeys.length === 0 ? (
          <Typography>No hay órdenes semanales completadas.</Typography>
        ) : (
          <>
            <Box sx={{ height: 250 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="total" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            {weekKeys.map((week) => (
              <Box key={week} mt={2}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Semana {week}
                </Typography>
                <OrdersList orders={groupsedByWeek[week]} />
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };

  // D) Vista de Órdenes Mensuales + Gráfica
  const OrdersMonthlyView = () => {
    const monthKeys = Object.keys(groupsedByMonth).sort();
    const chartData = monthKeys.map((m) => ({
      month: m,
      total: groupsedByMonth[m].length
    }));

    return (
      <Box>
        {monthKeys.length === 0 ? (
          <Typography>No hay órdenes mensuales completadas.</Typography>
        ) : (
          <>
            <Box sx={{ height: 250 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="total" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            {monthKeys.map((month) => (
              <Box key={month} mt={2}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Mes {month}
                </Typography>
                <OrdersList orders={groupsedByMonth[month]} />
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };

  // -----------------------------------------------------------------------------------
  // 7. Otras secciones personalizadas
  // -----------------------------------------------------------------------------------
  // A) Viajes Activos
  const OrdersPendientes = () => {
    if (!ordersHistory || !ordersHistory.pendientes) {
      return <Typography>No hay viajes pendientes.</Typography>;
    }

    const pendientes = ordersHistory.pendientes;

    return (
      <Box>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Pendientes por entrega o devolución hoy: {pendientes?.length}
        </Typography>
            <Box display="flex" gap={2}>
            {/* Botón para Descargar Circuit */}
            <Button
              variant="contained"
              startIcon={<DownloadIcon />} // Ícono de descarga de MUI
              color="primary"
              onClick={dowLoadCircuit}
            >
              Descargar Circuit
            </Button>

            {/* Select para Ordenar */}
            {/* <Box display="flex" alignItems="center">
              <Select
                value={sortOrder}
                onChange={handleSortChange}
                displayEmpty
                variant="outlined"
                sx={{ minWidth: 150 }}
              >
                <MenuItem value="" disabled>
                  Selecciona Ordenar por:
                </MenuItem>
                <MenuItem value="date">Fecha</MenuItem>
                <MenuItem value="name">Nombre</MenuItem>
                <MenuItem value="priority">Prioridad</MenuItem>
              </Select>
            </Box> */}
          </Box>

        {pendientes.length === 0 ? (
          <Typography>No hay viajes pendientes.</Typography>
        ) : (
          <Box mt={2}>
            {/* Reusamos nuestro componente OrdersList */}
            <OrdersList orders={pendientes} />
          </Box>
        )}
      </Box>
    );
  };

  



  // -----------------------------------------------------------------------------------
  // 8. Definir las pestañas del Historial de Viajes
  // -----------------------------------------------------------------------------------
  const panesOrdenes = [
    {
      label: "Ordenes diarias",
      content:  <OrdersDailyView groupsedByDay={groupsedByDay} ></OrdersDailyView>,
    },
    {
      label: "Ordenes Semanales",
      // content: <OrdersWeeklyView />,
      content:<WeeklyOrders orders={ordersHistory.completadas} />,
    },
    {
      label: "Ordenes Mensuales",
      content: <MonthlyOrders orders={ordersHistory.completadas}/>,
    },
  ];

  // Pestañas de Reclamos (ejemplo)
  const panesReclamos = [
    {
      label: "Reclamos Pendientes",
      content: <Box>Listar Pendientes</Box>,
    },
    {
      label: "Casos cerrados",
      content: <Box>Listar cerrados</Box>,
    },
    {
      label: "Casos en cobro",
      content: <Box>Listar ordenes</Box>,
    },
  ];

  // -----------------------------------------------------------------------------------
  // 9. Definir las secciones del menú lateral
  // -----------------------------------------------------------------------------------
  const sections = [
    {
      label: "Viajes Activos",
      content: <OrdersPendientes />,
    },
    {
      label: "Mapa Viajes Activos",
      content:  <MapaOrdersPendientes orders={ordersHistory.pendientes} setOrderData={setOrderData}/> ,
    },
    {
      label: "Buscar viajes por Rutas",
      content: <BuscarRutas userUid={userUid}/>,
    },
    {
      label: "Historial de Viajes",
      content: (
           <Box>
                <Typography variant="h6" gutterBottom>
                  Historial de Viajes
                </Typography>
            <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Diarias" />
            <Tab label="Semanales" />
            <Tab label="Mensuales" />
          </Tabs>
          <Divider />
          <Box p={0.5}>
            {tabIndex === 0 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
            {tabIndex === 1 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
            {tabIndex === 2 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
          </Box>
        </Box>
      ),
    },
    // {
    //   label: "Reclamos",
    //   content: (
    //     <Card>
    //       <CardHeader title="Reclamos" />
    //       <Tabs value={tabIndex} onChange={(e, nv) => setTabIndex(nv)} indicatorColor="primary">
    //         {panesReclamos.map((pane, index) => (
    //           <Tab key={index} label={pane.label} />
    //         ))}
    //       </Tabs>
    //       <Divider />
    //       <Box p={2}>{panesReclamos[tabIndex].content}</Box>
    //     </Card>
    //   ),
    // },
    // {
    //   label: "Historial de pagos",
    //   content: (
    //     <Typography variant="body1" align="center">
    //       Historial de pagos
    //     </Typography>
    //   ),
    // },
    {
      label: "Detalles de Cuenta",
      content: (
        <Box>
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">Nombre</th>
                <td>{userData.name || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">ID</th>
                <td>{userData.bicciNumber || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{userData.email || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">Teléfono</th>
                <td>{userData.phone || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">Dirección</th>
                <td>{userData.address || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">DNI</th>
                <td>{userData.dni || "No especificado"}</td>
              </tr>
              <tr>
                <th scope="row">Fecha de alta</th>
                <td>{userData.createdAt || "No especificado"}</td>
              </tr>
            </tbody>
          </table>
        </Box>
      ),
    },
    {
      label: "Desempeño",
      content: (
        <Typography variant="body1" align="center">
          No se muestran datos
        </Typography>
      ),
    },
    {
      label: "Carnet Rider",
      content: (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card className="carnetRider">
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={userData.avatar}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
                <strong>{userData.name}</strong>
                <strong>{userData.dni}</strong>
                <br />
                <div className="alignItemscenter">
                  {userData.active === true ? (
                    <span className="activo">Activo</span>
                  ) : (
                    <span className="inactivo">Inactivo</span>
                  )}
                </div>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
              <strong>ID: {userData.bicciNumber}</strong>
            </div>
            <CardActions>
              <div className="centercontent">
                <div className="borderCode">
                  <QRCode
                    value={`https://get-bicci.web.app/consulta/${userData.id}`}
                    size={150}
                    level="H"
                    fgColor="#741ab0"
                  />
                </div>
              </div>
            </CardActions>
            <div className="centercontent contac-data">
              Empresa: Bicci Delivery
              <br />
              CONTACTO <br />
              +56 9 4595 0049
              <br />
            </div>
          </Card>
        </Box>
      ),
    },
  ];

  // -----------------------------------------------------------------------------------
  // 10. Render principal (vista móvil: uso de Grid, xs=12, etc.)
  // -----------------------------------------------------------------------------------
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const onCloseAccions = () => {
    setOrderData(null)
    setOpenAccions(false)
  }

  const handleAsign = () => {
    // Lógica para agregar a tu usuario
    console.log("Agregando a tu usuario...");
    onCloseAccions();
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {/* Perfil del usuario y menú lateral */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box alignItems="center" textAlign="center">
                <Avatar
                  src={userData.avatar}
                  sx={{ height: 100, width: 100, margin: "auto" }}
                />
                <Typography typography={{ fontSize: "1em" }}>
                  {userData.name || "No especificado"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.email || "No especificado"}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography
                variant="body2"
                textAlign="center"
                sx={{ width: "100%" }}
              >
                {userData.active ? "Activo" : "Inactivo"}
              </Typography>
            </CardActions>
          </Card>

          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <List>
              {sections.map((section, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    selected={selectedSection === index}
                    onClick={() => setSelectedSection(index)}
                  >
                    <ListItemText primary={section.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        {/* Contenido principal según la sección seleccionada */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>{sections[selectedSection].content}</CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openAccions} onClose={onCloseAccions}>
            <Box padding={2}>
              
              {selectionOrderData ?  
              <>
              <Typography variant="h6">{selectionOrderData.id}</Typography>
              <List>
                <ListItem disablePadding>
                  {selectionOrderData.userName}
                </ListItem>
                  <ListItem disablePadding>
                  {selectionOrderData.userPhone}
                </ListItem>
                  <ListItem disablePadding>
                  {selectionOrderData.toAddress}
                </ListItem>
                  <ListItem disablePadding>
                  {selectionOrderData.status}
                </ListItem>
                  <ListItem disablePadding>
                  {selectionOrderData.intState}
                </ListItem>
                  <ListItem disablePadding>
                  {selectionOrderData.observationAdmin}
                 </ListItem>
              </List> </>: <></>}
            
       
              <Box marginTop={2} display="flex" justifyContent="space-between">


                {/* <Button
                  variant="contained"
                  onClick={handleAsign}// Deshabilita si no hay motivo seleccionado
                >
                  Agregar
                </Button> */}
                <Button variant="outlined" color="error" onClick={onCloseAccions}>
                  cerrar
                </Button>
              </Box>
            </Box>
          </Dialog>
    
      
    </Box>
  );
};

export default Micuenta;
