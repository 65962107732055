import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Box, Button, AppBar } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../Logo/Logo';
import Movilnavbar from '../Movilnavbar/Movilnavbar';

const Navbar = (props) => {
  const activo = useSelector((store) => store.usuario.activo);

  React.useEffect(() => {}, [activo]);

  return (
    <AppBar
      sx={{
        backgroundColor: 'white',
        
        height: '80px',
      }}
    >
      <Grid container spacing={2} alignItems="center" id="navbarconntainer">
        {/* Logo */}
        <Grid
          item
          xs={6}
          sm={2}
          md={2}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Link to="/">
            <Logo />
          </Link>
        </Grid>
  
        {/* Centro: Menú móvil */}
        <Grid
          item
          xs={6}
          sm={8}
          md={8}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Movilnavbar props={props} />
        </Grid>
  
        {/* Social Icons */}
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Box id="socialcontainer" sx={{ display: 'none', justifyContent: 'center' }}>
            <Button
              component={Link}
              to="#"
              startIcon={<FacebookIcon />}
              sx={{
                color: 'purple',
                backgroundColor: 'transparent',
                ':hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            />
            <Button
              component={Link}
              to="#"
              startIcon={<InstagramIcon />}
              sx={{
                color: 'purple',
                backgroundColor: 'transparent',
                ':hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
  
};

export default Navbar;
