import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Alert,
  Typography,
  Grid,
} from '@mui/material';
import { ingresoUsuarioLogin } from '../../redux/usuarioDucks';
import { useDispatch, useSelector } from 'react-redux';
import BtnAceceder from './BtnAceceder';

const FormLogin = (props) => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);

  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError('Ingrese Email');
      return;
    }
    if (!pass.trim()) {
      setError('Ingrese Contraseña');
      return;
    }
    if (pass.length < 5) {
      setError('Contraseña mayor a 6 caracteres');
      return;
    }

    if (resperror !== null) {
      setError('Error de acceso');
    }

    setError(null);
    Login();
  };

  const Login = React.useCallback(async () => {
    setSuccess(null);
    try {
      dispatch(ingresoUsuarioLogin(email, pass));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, email, pass]);

  return (
    <Box
      sx={{
        maxWidth: 500,
        margin: 'auto',
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
      <Typography variant="h5" gutterBottom align="center">
        Iniciar Sesión
      </Typography>
      <form onSubmit={procesarDatos}>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {resperror && <Alert severity="error">{resperror}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              label="Correo electrónico"
              placeholder="email@dominio.com"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              label="Contraseña"
              placeholder="*************"
              variant="outlined"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mb: 2 }}
            >
              Entrar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              component={NavLink}
              to="/resset-pass"
            >
              Crear o recuperar contraseña
            </Button>
          </Grid>
        </Grid>
      </form>
      <Box mt={2}>
        <BtnAceceder />
      </Box>
    </Box>
  );
};

export default FormLogin;
