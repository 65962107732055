import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import CustomBarChart from "../CustomBarChart/CustomBarChart";

const MonthlyOrders = ({ orders }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [months, setMonths] = useState([]);
  const [totalDeliveries, setTotalDeliveries] = useState(0);

  useEffect(() => { 
    // Generar meses basados en el calendario
    const generatedMonths = generateMonths();
    console.log("Meses generados:", generatedMonths);
    setMonths(generatedMonths);

    if (generatedMonths.length > 0) {
      const defaultMonth = generatedMonths[0].start.toISOString().split("T")[0];
      console.log("Mes seleccionado por defecto:", defaultMonth);
      setSelectedMonth(defaultMonth); // Seleccionar el primer mes por defecto
    }
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      const groupedByMonth = groupOrdersByMonth(orders);
      updateChartData(selectedMonth, groupedByMonth);
    }
  }, [orders, selectedMonth]);

  const generateMonths = () => {
    const today = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const startOfMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() - i + 1, 0);

      months.push({
        start: startOfMonth,
        end: endOfMonth,
      });
    }

    return months;
  };

  const groupOrdersByMonth = (orders) => {
    const grouped = {};

    orders.forEach((order) => {
      const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
      if (!date) return;

      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

      if (!grouped[monthKey]) {
        grouped[monthKey] = [];
      }
      grouped[monthKey].push({ ...order, date });
    });

    return grouped;
  };

  const convertFirestoreTimestampToDate = (timestamp) => {
    if (!timestamp) return null;
    if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
      return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
    }
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  };

  const updateChartData = (monthKey, groupedByMonth) => {
    const monthOrders = groupedByMonth[monthKey] || [];
    const [year, month] = monthKey.split("-");

    const daysInMonth = new Date(year, month, 0).getDate();

    // Generar datos para los días del mes
    const dailyData = Array.from({ length: daysInMonth }, (_, i) => {
      const dayDate = new Date(year, month - 1, i + 1);
      const dayKey = dayDate.toISOString().split("T")[0];

      // Buscar cuántas órdenes corresponden a este día
      const totalOrders = monthOrders.filter(
        (order) => order.date.toISOString().split("T")[0] === dayKey
      ).length;

      return {
        // Formato: "01 Lun."
        day: `${dayDate.getDate()}`,
        total: totalOrders, // Si no hay órdenes, será 0
      };
    });

    setChartData(dailyData);
    const totalDeliveries = dailyData.reduce((sum, item) => sum + item.total, 0);
    setTotalDeliveries(totalDeliveries);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };

  return (
    <Box>
      {chartData.length === 0 ? (
        <Typography>No hay órdenes mensuales completadas.</Typography>
      ) : (
        <>
         <Box sx={{ padding: "0px", backgroundColor: "#fff", borderRadius: "8px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                 Entregas: {totalDeliveries} {/* Mostrar total de entregas */}
            </Typography>
            <CustomBarChart data={chartData} />
          </Box>
        

          <Box
            display="flex"
            justifyContent="flex-start"
            my={2}
            sx={{
              overflowX: "auto", // Activar desplazamiento horizontal
              whiteSpace: "nowrap", // Evitar que los botones se envuelvan
              width: "100%", // Asegurar que el contenedor ocupe todo el ancho
            }}
          >
            {months.map((month, index) => {
              const monthKey = `${month.start.getFullYear()}-${String(
                month.start.getMonth() + 1
              ).padStart(2, "0")}`;
              return (
                <Button
                  key={monthKey}
                  variant={selectedMonth === monthKey ? "contained" : "outlined"}
                  onClick={() => handleMonthChange(monthKey)}
                  sx={{ mx: 1, fontSize: 10, minWidth: "200px", m: 1 }}
                >
                  {month.start.toLocaleDateString("es-ES", { month: "long", year: "numeric" })}
                </Button>
              );
            })}
          </Box>

          {selectedMonth && (
            <Box mt={2}>
              <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                Mes {selectedMonth}
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MonthlyOrders;
