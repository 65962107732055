import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Importa el hook useNavigate
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { actualizarPassPorEnvioEmail } from "../../redux/acccionesClientes";

const Rcontraseña = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Inicializa el hook useNavigate
  const [userPassReset, setUserPassReset] = React.useState('');
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handlePasswordReset = (e) => {
    e.preventDefault();

    if (!userPassReset.trim()) {
      setError('Ingrese su correo electrónico');
      return;
    }

    const data = { email: userPassReset };
    setError(null);
    setSuccess('El mensaje fue enviado a tu correo electrónico.');

    dispatch(actualizarPassPorEnvioEmail(data));

    setTimeout(() => {
      setSuccess(null);
      navigate('/login'); // Usa navigate en lugar de props.history.push
    }, 2000);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              Crear o Recuperar Contraseña
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: '100%' }}>
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" sx={{ width: '100%' }}>
                {success}
              </Alert>
            )}

            <form onSubmit={handlePasswordReset} style={{ width: '100%' }}>
              <TextField
                fullWidth
                label="Correo Electrónico"
                type="email"
                variant="outlined"
                placeholder="email@dominio.com"
                value={userPassReset}
                onChange={(e) => setUserPassReset(e.target.value)}
                required
                InputProps={{
                  startAdornment: <Box component="span">@</Box>,
                }}
                sx={{ mb: 2 }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 2,
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Enviar Email
                </Button>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Rcontraseña;
