import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const DevelopComponent = (props) => {
  console.log(props);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} id="Buscadorletf">
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Box 
            component="img"
            className="plaholderimg"
            src="/mantenimiento.jpg"
            alt="Mantenimiento"
            sx={{ width: '100%', maxHeight: '400px', objectFit: 'cover' }}
          />
        </NavLink>
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<TravelExploreIcon />}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Regresar
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default DevelopComponent;
