import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormLogin from "./FormLogin";
import Logo from "../Header/Logo/Logo";
import { Box, Typography } from "@mui/material";

function LoginEmail(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activo = useSelector((store) => store.usuario.activo);

  React.useEffect(() => {
    if (activo === true) {
      navigate("/mi-cuenta");
    }
  }, [activo, navigate]);

  return (
    <Box
      className="formcontainer"
      sx={{
        marginTop: 5,
        textAlign: "center",
        padding: 3,
        backgroundColor: "white",
        maxWidth: 500,
        margin: "auto",
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box id="brandsLogo" sx={{ marginBottom: 3 }}>
        <Logo />
      </Box>
      <Typography variant="h4" gutterBottom>
        INICIAR SESIÓN
      </Typography>
      <FormLogin />
    </Box>
  );
}

export default LoginEmail;
