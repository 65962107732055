import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { db } from "../../../firebase";
import moment from "moment";

class ActualizarDriverPerformance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      TotalTime: "",
      Cumplimiento: "",
      Vestimenta: "",
      Niveldeservicio: "",
      Puntualidad: "",
      Ordenes: "",
      createdAt: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      LastPediodoAdd: "",
      data: "",
      dataComplete: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (value < 0) this.setState({ [name]: 0 });
    else if (value > 100) this.setState({ [name]: 100 });
    else this.setState({ [name]: value });
  };

  handleChangetime = (e) => {
    const { name, value } = e.target;
    const formattedDate = moment(value).format("DD MMMM YYYY, h:mm:ss a");
    this.setState({ [name]: formattedDate });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.loadDataArray();

    const isValid = await this.loadValidation();
    if (!isValid) return;

    try {
      await db
        .collection("drivers")
        .doc(this.props.id)
        .collection("driverPerformance")
        .doc()
        .set(this.state);
      this.handleClear();
    } catch (error) {
      console.error("Error updating performance data:", error);
    }
  };

  loadDataArray = async () => {
    const {
      TotalTime,
      Cumplimiento,
      Vestimenta,
      Niveldeservicio,
      Puntualidad,
      Ordenes,
    } = this.state;

    this.setState({
      data: [
        parseInt(TotalTime),
        parseInt(Cumplimiento),
        parseInt(Vestimenta),
        parseInt(Niveldeservicio),
        parseInt(Puntualidad),
        parseInt(Ordenes),
      ],
    });
  };

  loadValidation = async () => {
    const {
      TotalTime,
      Cumplimiento,
      Vestimenta,
      Niveldeservicio,
      Puntualidad,
      Ordenes,
      LastPediodoAdd,
    } = this.state;

    if (!TotalTime.trim())
      return this.setState({ dataComplete: "Por favor complete TotalTime" });
    if (!Cumplimiento.trim())
      return this.setState({ dataComplete: "Por favor complete Cumplimiento" });
    if (!Vestimenta.trim())
      return this.setState({ dataComplete: "Por favor complete Vestimenta" });
    if (!Niveldeservicio.trim())
      return this.setState({ dataComplete: "Por favor complete Nivel de servicio" });
    if (!Puntualidad.trim())
      return this.setState({ dataComplete: "Por favor complete Puntualidad" });
    if (!Ordenes.trim())
      return this.setState({ dataComplete: "Por favor complete Ordenes" });
    if (!LastPediodoAdd.trim())
      return this.setState({ dataComplete: "Por favor complete LastPediodoAdd" });

    return true;
  };

  handleClear = () => {
    this.setState({
      TotalTime: "",
      Cumplimiento: "",
      Vestimenta: "",
      Niveldeservicio: "",
      Puntualidad: "",
      Ordenes: "",
      createdAt: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      LastPediodoAdd: "",
      data: "",
      dataComplete: "",
    });
    this.props.handleSetTogle();
  };

  render() {
    const {
      TotalTime,
      Cumplimiento,
      Vestimenta,
      Niveldeservicio,
      Puntualidad,
      Ordenes,
      LastPediodoAdd,
      dataComplete,
    } = this.state;

    return (
      <Card sx={{ maxWidth: 500, margin: "0 auto", mt: 3 }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Actualizar Desempeño del Conductor
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {dataComplete && <Alert severity="error">{dataComplete}</Alert>}

          <form onSubmit={this.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Fecha del Período"
              type="date"
              name="LastPediodoAdd"
              value={LastPediodoAdd}
              onChange={this.handleChangetime}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Tiempo Total (%)"
              type="number"
              name="TotalTime"
              value={TotalTime}
              onChange={this.handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Cumplimiento (%)"
              type="number"
              name="Cumplimiento"
              value={Cumplimiento}
              onChange={this.handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Vestimenta (%)"
              type="number"
              name="Vestimenta"
              value={Vestimenta}
              onChange={this.handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Nivel de Servicio (%)"
              type="number"
              name="Niveldeservicio"
              value={Niveldeservicio}
              onChange={this.handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Puntualidad (%)"
              type="number"
              name="Puntualidad"
              value={Puntualidad}
              onChange={this.handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Órdenes"
              type="number"
              name="Ordenes"
              value={Ordenes}
              onChange={this.handleChange}
            />
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    );
  }
}

export default ActualizarDriverPerformance;
