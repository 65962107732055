import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <Card sx={{ backgroundColor: "#f3f4f6", boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h1" color="error" gutterBottom>
            404
          </Typography>
          <Typography variant="h4" gutterBottom>
            Página no encontrada
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            Puede que haya ingresado de manera incorrecta la dirección URL en su
            navegador. Es posible que el contenido haya sido movido o eliminado
            por el administrador del sitio.
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoBack}
              sx={{ mr: 2 }}
            >
              Volver al Inicio
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              href="mailto:admin@example.com"
            >
              Contactar al Administrador
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Page404;
