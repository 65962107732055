import styled from "styled-components";
import React, { useState }  from 'react';
import Navbar from './NavbarWrapper';
import MenuButton  from "./MenuButton";
import { Box } from "@mui/material";

function Movilnavbar() {

const [open, setOpen] = useState(false);

const handleClick = () => {
  setOpen(!open);
}; 
  return(
    <Box>
    <Navbar open={open} handleClick={handleClick}  />
    </Box>
  
    );
}
export default Movilnavbar;
