import axios from "axios";


export const assingMultipleDriver = (dataAsid) => async () => {
  try {
    let data = JSON.stringify(dataAsid);
    console.log(dataAsid)
    
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BICCI_API_PRO_MULTIPLE_ASSIGN_DRIVER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        console.log(response.data);
       
      })
      .catch((error) => {
         console.log(error.response.data);
        
      });
       
  } catch (error) {
     console.log(error.response.data);
    
  }
};