import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfigPro = {
     apiKey: `${process.env.REACT_APP_RIDERS_API_KEY}`,
     authDomain: `${process.env.REACT_APP_RIDERS_AUTHDOMAIN}`,
     databaseURL: `${process.env.REACT_APP_RIDERS_DATABASEURL}`,
     projectId: `${process.env.REACT_APP_RIDERS_PROYECID}`,
     storageBucket: `${process.env.REACT_APP_RIDERS_STORAGEBUCKET}`,
     messagingSenderId: `${process.env.REACT_APP_RIDERS_MESSAGINGSENDERIR}`,
     appId: `${process.env.REACT_APP_RIDERS_APPID}`,
     measurementId: `${process.env.REACT_APP_RIDERS_MEASUREMENTID}`
}

const app = initializeApp(firebaseConfigPro);
const storage = getStorage(app) 
const auth = getAuth(app);
const db = getFirestore(app);



export {auth, db, firebase, storage}