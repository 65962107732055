import React from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { db } from "../../../firebase";
import moment from "moment";

class ActualizarScheduleTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Ordenes: "",
      Distancia: "",
      TiempoHandOff: "",
      TiempoPreparacion: "",
      TiempoTrayecto: "",
      TiempoEntrega: "",
      Velocidad: "",
      TotalTime: "",
      createdAt: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      LastPediodoAdd: "",
      data: "",
      dataComplete: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value > 0 ? value : 0 });
  };

  handleChangeTimes = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: Math.min(Math.max(value, 1), 16) });
  };

  handleChangetime = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: moment(value).format("DD MMMM YYYY, h:mm:ss a"),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.loadDataArray();
    const isValid = await this.loadValidation();

    if (isValid) {
      try {
        await db
          .collection("drivers")
          .doc(this.props.id)
          .collection("scheduleTimes")
          .doc()
          .set(this.state);
        this.handleClear();
      } catch (error) {
        console.error("Error updating schedule times:", error);
      }
    }
  };

  loadDataArray = async () => {
    this.setState({
      data: [
        parseInt(this.state.TiempoHandOff),
        parseInt(this.state.TiempoPreparacion),
        parseInt(this.state.TiempoTrayecto),
        parseInt(this.state.TiempoEntrega),
        parseInt(this.state.TotalTime),
      ],
    });
  };

  loadValidation = () => {
    const requiredFields = [
      "Ordenes",
      "Distancia",
      "TiempoHandOff",
      "TiempoPreparacion",
      "TiempoTrayecto",
      "TiempoEntrega",
      "Velocidad",
      "TotalTime",
      "LastPediodoAdd",
    ];

    for (let field of requiredFields) {
      if (!this.state[field]?.trim()) {
        this.setState({
          dataComplete: `Por favor complete los datos: ${field}`,
        });
        return false;
      }
    }

    this.setState({ dataComplete: "" });
    return true;
  };

  handleClear = () => {
    this.setState({
      Ordenes: "",
      Distancia: "",
      TiempoHandOff: "",
      TiempoPreparacion: "",
      TiempoTrayecto: "",
      TiempoEntrega: "",
      Velocidad: "",
      TotalTime: "",
      createdAt: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      LastPediodoAdd: "",
      data: "",
      dataComplete: "",
    });
    this.props.handleSetTogle?.();
  };

  render() {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Actualizar Schedule Times
          </Typography>
          <Box component="form" onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Último Periodo"
                  type="date"
                  name="LastPediodoAdd"
                  onChange={this.handleChangetime}
                  value={this.state.LastPediodoAdd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Órdenes"
                  type="number"
                  name="Ordenes"
                  onChange={this.handleChange}
                  value={this.state.Ordenes}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Distancia promedio"
                  type="number"
                  name="Distancia"
                  onChange={this.handleChange}
                  value={this.state.Distancia}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tiempo en toma de pedido"
                  type="number"
                  name="TiempoHandOff"
                  onChange={this.handleChangeTimes}
                  value={this.state.TiempoHandOff}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tiempo de Preparación"
                  type="number"
                  name="TiempoPreparacion"
                  onChange={this.handleChangeTimes}
                  value={this.state.TiempoPreparacion}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tiempo de Trayecto"
                  type="number"
                  name="TiempoTrayecto"
                  onChange={this.handleChangeTimes}
                  value={this.state.TiempoTrayecto}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tiempo de Entrega"
                  type="number"
                  name="TiempoEntrega"
                  onChange={this.handleChangeTimes}
                  value={this.state.TiempoEntrega}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Velocidad"
                  type="number"
                  name="Velocidad"
                  onChange={this.handleChange}
                  value={this.state.Velocidad}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Tiempo Total"
                  type="number"
                  name="TotalTime"
                  onChange={this.handleChangeTimes}
                  value={this.state.TotalTime}
                />
              </Grid>
            </Grid>
            {this.state.dataComplete && (
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 2 }}
                align="center"
              >
                {this.state.dataComplete}
              </Typography>
            )}
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Button variant="contained" color="primary" type="submit">
                Agregar
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
}

export default ActualizarScheduleTimes;
