import React from 'react'
import { DataGrid } from '@mui/x-data-grid';


 export default function RidersTable(props) {
  console.log (props.props)

  function handleGoProps (e){
  console.log(e.target.id)
  console.log(props)
  props.props.history.push(`/admin/Metricas-riders/${e.target.id}`)
    }

  const rows2 = props.riders
  delete rows2.count;
  delete rows2.next;
  delete rows2.previous;
  delete rows2.results;
  const arrayelement = Object.values(rows2)

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    // {
    //   field: 'firstName',
    //   headerName: 'First name',
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: 'lastName',
    //   headerName: 'Last name',
    //   width: 150,
    //   editable: true,
    // },

    {
       field: 'avatar',
       headerName: 'Foto',
       width: 100,
       editable: false,
       type: 'Image',
       renderCell: (arrayelement) => {
        var url = arrayelement.row.avatar
        if(url){
        return (
          <img src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fuser-profile-icon.jpg?alt=media&token=e22f14e3-7b51-425b-b57c-072dda009ea8" 
            className="MuiAvatar-img" 
            alt = ""
            style={{ width: 40, height:40, borderRadius:50 }}
            ></img>
        );}else{  return (
          <img src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fuser-profile-icon.jpg?alt=media&token=e22f14e3-7b51-425b-b57c-072dda009ea8" 
          className="MuiAvatar-img" 
          alt = ""
          style={{ width: 40, height:40, borderRadius:50 }}
          ></img>
      )}
      },

     },
    {
      field: 'name',
      headerName: 'Nombre',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      editable: false,
      width: 160,
      // valueGetter: (params) =>
      //   `${params.getValue(params.id, 'firstName') || ''} ${
      //     params.getValue(params.id, 'lastName') || ''
      //   }`,
    },
    {
        field: 'email',
        headerName: 'Correo',
        width: 220,
        editable: false,
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 160,
        editable: false,
      },
      {
        field: 'active',
        headerName: 'estado',
        width: 160,
        editable: false,
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        width: 150,
        renderCell: (arrayelement) => {   
          // console.log(arrayelement)
          // var id = arrayelement.row.id
          return (
              <button
                className='btn-bicci-small btn-globalhover-calipso'
                variant="contained"
                color="primary"
                size="small"
                id={arrayelement.id}
                style={{ marginLeft: 16 }}
               onClick={handleGoProps}
              >
                Subir data
              </button>
             )
            },
      },
  ];

;
  
  const rows = [
    { id: 1, name: 'Snow', firstName: 'Jon', age: 35,  },
    { id: 2, name: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, name: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, name: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, name: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, name: 'Melisandre', firstName: null, age: 150 },
    { id: 7, name: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, name: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, name: 'Roxie', firstName: 'Harvey', age: 65 },
    { id: 10, name: 'Snow', firstName: 'Jon', age: 35,  },
    { id: 12, name: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 13, name: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 14, name: 'Stark', firstName: 'Arya', age: 16 },
    { id: 15, name: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 16, name: 'Melisandre', firstName: null, age: 150 },
    { id: 17, name: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 18, name: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 19, name: 'Roxie', firstName: 'Harvey', age: 65 },
  ]

 // console.log(rows)

        return (
          <div style={{ height: 600, width: '100%', backgroundColor:'#f8f8f8' }}>
            <DataGrid
              rows={arrayelement}
              //getRowId={'id'}
              getRowId={(arrayelement) => arrayelement.id} 
              columns={columns}
             
              pageSize={25}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
            />
          </div>
        );
      }

