import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Navbar from './components/Header/Navbar/Navbar';
import Footer from './components/Footer';
import Bicciriders from './Pages/Web/Bicciriders/Bicciriders';
import Micuenta from './Pages/Web/Micuenta';
import LoginEmail from './components/LoginEmail/LoginEmail';
import Rcontraseña from './components/LoginEmail/Rcontraseña';
import Page404 from './Pages/Page404';
import Adminriders from './Pages/Adminriders/Adminriders';
import Managementriders from './Pages/Adminriders/Managementriders/Managementriders';
import Metricsriders from './Pages/Adminriders/Metricsriders/Metricsriders';
import DetalleMetricsriders from './Pages/Adminriders/Metricsriders/DetalleMetricsriders';
import { auth } from './firebase';
import { cerrarSesionAccion } from './redux/usuarioDucks';
import Consultaonlineid from './components/Consultaonlineid/Consultaonlineid';

function App() {
  const dispatch = useDispatch();
  const [firebaseUser, setFirebaseUser] = React.useState(false);
  const [landScrenMenu, setLandScrenMenu] = React.useState(false);

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged((user) => {
        setFirebaseUser(user || 'invitado');
      });
    };
    fetchUser();
  }, []);

  const RutaPrivada = ({ children }) => {
    const usuarioStorage = JSON.parse(localStorage.getItem('userRider'));
    if (usuarioStorage && usuarioStorage.uid === firebaseUser.uid) {
      return children;
    } else {
      dispatch(cerrarSesionAccion());
      return <Navigate to="/login" />;
    }
  };

  const RutaProtegida = ({ children }) => {
    const usuarioStorage = JSON.parse(localStorage.getItem('userRider'));
    if (
      usuarioStorage &&
      usuarioStorage.uid === firebaseUser.uid &&
      process.env.REACT_APP_RIDERS_ADMIN.indexOf(usuarioStorage.roll) !== -1
    ) {
      return children;
    } else {
      return <Navigate to="/404" />;
    }
  };

  const expamAdmin = () => {
    setLandScrenMenu(!landScrenMenu);
  };

  return (
    <div>
      {firebaseUser === false ? (
        <div>Cargando...</div>
      ) : (
        <>
    <Router>
  <Navbar />
  <div className="container mt-3 containerbody">
    <Routes>
      {/* Rutas Públicas */}
      <Route path="/" element={<Bicciriders />} />
      <Route path="/login" element={<LoginEmail />} />
      <Route path="/resset-pass" element={<Rcontraseña />} />
      <Route path="/consulta/:id" element={<Consultaonlineid expamadmin={setLandScrenMenu} />} />

      {/* Rutas Privadas */}
      <Route
        path="/mi-cuenta"
        element={
          <RutaPrivada>
            <Micuenta />
          </RutaPrivada>
        }
      />

      {/* Rutas Protegidas */}
      <Route
        path="/admin"
        element={
          <RutaProtegida>
            <Adminriders />
          </RutaProtegida>
        }
      />
      <Route
        path="/admin/gestion-riders"
        element={
          <RutaProtegida>
            <Managementriders />
          </RutaProtegida>
        }
      />
      <Route
        path="/admin/metricas-riders"
        element={
          <RutaProtegida>
            <Metricsriders />
          </RutaProtegida>
        }
      />
      <Route
        path="/admin/metricas-riders/:id"
        element={
          <RutaProtegida>
            <DetalleMetricsriders />
          </RutaProtegida>
        }
      />

      {/* Ruta para 404 */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  </div>
  <Footer />
</Router>

        </>
      )}
    </div>
  );
}

export default App;
